import styled from "styled-components";
import { Button } from "../../StyledComponents";
import { Div } from "../../Billing/MyCards/Styles";

export const CardContent = styled.div`
  background: #ffffff;
  position: relative;
  border: 1px solid #d8e0f0;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  cursor: ${(p) => p.cursor && p.cursor};
  grid-area: cards;
  @media (min-width: 1280px) {
    // margin-top: 32px;
  }
`;

export const BtnSpan = styled.span`
  padding-left: 14px;
  float: left;
  margin-top: 1px;
  margin-right: 7px;
  font-size: 16px;
`;

export const BtnSpanAd = styled(BtnSpan)`
  padding-left: 0;
  font-size: 15px;
`;

export const ShadowCard = styled.span`
  background: linear-gradient(104deg, #194bfb 6.07%, #3a49f9 93.22%);
  width: 215px;
  height: 30px;
  border-radius: 8px;
  position: absolute;
  opacity: 0.8;
  filter: blur(32px);
  top: 175px;
`;

export const Card_Button = styled(Button)`
  font-size: ${(p) => p.size || "14px"};
  margin: ${(p) => p.margin || "16px"};
  padding: 0.25em 1em;
  background: none;
`;

export const Inner_carouse = styled(Div)`
  display: flex;
  white-space: nowrap;
  transition: transform 0.3s;
  transform: ${(props) =>
    props.actIndex === 0
      ? "translateX(0%)"
      : props.actIndex === 1
      ? "translateX(-100%)"
      : "translateX(-200%)"};
`;

export const Indic_Btn = styled(Div)`
  background: ${(props) => (props.active === "active" ? "#232934" : "#cbd1fd")};
  width: ${(props) => (props.active === "active" ? "24px" : "14px")};
  height: ${(props) => (props.active === "active" ? "4px" : "3px")};
  cursor: pointer;
  margin-top: ${(props) => (props.active === "active" ? "9px" : "10px")};
  display: ${(props) => (props.val === 3 ? "none" : "")};
  margin-right: ${(props) => props.marginRight && props.marginRight};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
`;

export const DivAddCard = styled(Div)`
  width: 320px;
  height: 194px;
  background-color: #fff;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: #363636;
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  margin: 0 16px;

  ${(p) =>
    p.nocard &&
    `
    width:unset;
  //  height:220px;
  margin: 0 16px 15px 16px;
      
    `}
`;

export const CarouselContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  color: #fff;
  margin-top: -15px;
`;
