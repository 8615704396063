import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetReEmailRes,
  setReEmailRes,
} from "../../../StateManagement/Reducers/PreLoginState";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Description,
  Span,
} from "../../StyledComponents";
import {
  Container,
  Input,
  TitleImage,
  Title,
  ImageContainer,
  Atag,
} from "../StyledComponents";
import LoginLeft from "../LoginLeft";

import ResetImage from "../../../Assets/images/reset.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import {
  globalColor,
  globalFont,
  hoverColor,
} from "../../utils/RepeatFunctions/Schedule";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const emailResponse = useSelector((state) => state.PreLogin.ReEmailResp);
  const text = useSelector((state) => state.PreLogin.logo);

  const HandleDispatch = (e) => {
    e.preventDefault();
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    if (email !== "") {
      dispatch(GetReEmailRes({ type: { email: email } }));
      setModalAlerShow(true);
      setStatus("success");
      setModalMsg("Your details are being verified.");
    } else {
      setMailErr(true);
      setErrMsg("Please enter the Email address");
    }
  };

  const HandleNavigate = (e) => {
    e.preventDefault();
    dispatch(setReEmailRes({}));
    Navigate("/Login");
  };

  const EmailCheck = (e) => {
    setemail(e.target.value);
    setMailErr(false);
    // const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    // if (regex.test(e.target.value)) {
    //   setMailErr(false);
    // } else {
    //   setMailErr(true);
    //   setErrMsg("Invalid Email");
    // }
  };

  const handleBlur = () => {
    const value = email.trim();
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (value === "") {
      setMailErr(false);
      setErrMsg("");
    } else if (!regex.test(value)) {
      setMailErr(true);
      setErrMsg("Invalid Email");
    } else {
      setMailErr(false);
      setErrMsg("");
    }
  };

  useEffect(() => {
    if (emailResponse.status === "Success") {
      setModalAlerShow(false);

      Navigate("/ReVerifyAccount", {
        state: { type: { email }, reset: true },
      });
    } else if (emailResponse.status === "Failure") {
      setModalAlerShow(false);

      setMailErr(true);
      setErrMsg("Please enter Registered email");
    }
  }, [emailResponse]);

  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" backColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backColor="#FFFFFF">
        <Div width="100%" display="flex">
          <Div width="475px" textAlign="center" margin="auto">
            {text.length !== 0 && (
              <ImageContainer marginTop="72px" marginBottom="55px">
                <Image
                  loading="lazy"
                  src={File_URL + text}
                  height="41px"
                  width="173px"
                />
              </ImageContainer>
            )}
            <TitleImage
              src={ResetImage}
              alt="Email Verified"
              width="124px"
              height="124px"
              marginTop="10%"
            ></TitleImage>
            <Title
              color="#000000"
              fontWeight="800"
              fontSize="32px"
              lineHeight="44px"
              marginBottom="12px"
            >
              Forget Password
            </Title>
            <Description
              fontSize="20px"
              lineHeight="27px"
              alignItems="center"
              color=" #667085"
              marginBottom="32px"
            >
              Provide your email &amp; we’ll be sending you a verification code
              to reset your password.
            </Description>
            <Form>
              <FormGroup marginBottom="41px" display="grid" width="100%">
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  fontSize="14px"
                  color="#7D8592"
                  fontWeight="600"
                  lineHeight="24px"
                >
                  Email ID
                </FormLabel>
                <Input
                  color="#7D8592"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => EmailCheck(e)}
                  onBlur={handleBlur}
                />
                {mailErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily={globalFont}
                  >
                    {errMsg}
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup marginBottom="40px" display="grid" width="100%">
                <Button
                  background={globalColor}
                  hoverBackColor={hoverColor}
                  className="save_active"
                  padding="18px 140px 18px 140px"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  letterSpacing="1.1px"
                  disabled={btndis}
                  onClick={(e) => HandleDispatch(e)}
                >
                  Send Verification Code
                </Button>
              </FormGroup>

              <Span>Back to</Span>
              <Atag
                color={globalColor}
                borderBottom={`1px solid ${globalColor}`}
                margin="0px 0px 0px 10px"
                onClick={(e) => HandleNavigate(e)}
              >
                Login
              </Atag>
            </Form>
          </Div>
        </Div>
      </Div>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Container>
  );
};

export default ForgetPassword;
