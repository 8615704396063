import React, { useState, useEffect } from "react";
import { GradientCircularProgress } from "react-circular-gradient-progress";
import { useSelector, useDispatch } from "react-redux";

import { toast } from "react-toastify";

import { GetPayBal } from "../../../StateManagement/Reducers/DashboardState";
import {
  GetInvoiceList,
  GetCardDetails,
} from "../../../StateManagement/Reducers/BillingState";

import MakePayment from "../../StyledComponents/MakePayment";
import Error from "../../../Modules/StyledComponents/Toastify/Error";

import { Div, PageSubTitle, Button, Text } from "../../StyledComponents";
import {
  ModalDialog,
  OuterContainerbox,
  Row,
  PaymentsText,
  PaymentBalanceData,
  BorderData,
  ChartBox,
  ChartBox2,
  ChartBoxspan,
} from "./styles";
import StripeAlert from "../../StyledComponents/StripeAlert";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

const BillOverview = () => {
  const dispatch = useDispatch();

  const [makepaymentshow, setmakepaymentshow] = useState(false);
  const [PayAmt, setPayAmt] = useState(true);
  const [BalanceAmt, setBalanceAmt] = useState(false);

  const InvoiceList = useSelector((state) =>
    state.Billing.InvoList.filter(
      (Invoiceitm) => Invoiceitm.total_balance !== "0.00"
    )
  );
  const Pay_bal = useSelector((state) => state.Dashboard.PayementBalance);
  const CardDetails = useSelector((state) => state.Billing.CardDetail);

  const DefaultCardDetails = CardDetails.map(
    (card) => card.def === "1" && card.id
  );
  const stripeAllowed = useSelector((state) => state.Billing.stripeAllowed);
  const [ModalAlerShow, setModalAlerShow] = useState(false);

  const handleModalClick = () => {
    setModalAlerShow(false);
  };

  const SuccessAmount = (msg) => {
    setTimeout(() => dispatch(GetInvoiceList()), 1000);
    setTimeout(() => dispatch(GetPayBal()), 1000);
  };

  const NoDueAlert = (msg) => {
    toast(<Error msg={msg} />, {
      containerId: "B",
      className: "error_badge",
    });
  };
  const HideBal = () => {
    setBalanceAmt(false);
    setPayAmt(true);
  };

  const HidePay = () => {
    setBalanceAmt(true);
    setPayAmt(false);
  };

  useEffect(() => {
    dispatch(GetInvoiceList());
    dispatch(GetPayBal());
    dispatch(GetCardDetails());
  }, [dispatch]);

  return (
    <>
      <OuterContainerbox>
        <Div padding="20px" borderRadius="8px">
          <PageSubTitle clr="#232934">Billing Overview</PageSubTitle>
        </Div>
        <Div padding="0px 20px">
          <Row>
            <PaymentsText
              background="#01c0f6"
              marginLeft="12px"
              color="#01c0f6"
            >
              Your Payments
            </PaymentsText>
            <PaymentsText background="#f97066" color="#f97066">
              Your Balance
            </PaymentsText>
          </Row>
          <Row marginTop="8px">
            <PaymentBalanceData
              fontSize={Pay_bal.payment_length > 13 ? "12px" : "16px"}
            >
              $&nbsp;{Pay_bal.payment ? Pay_bal.payment : "00.00"}
            </PaymentBalanceData>
            <PaymentBalanceData
              fontSize={Pay_bal.pat_bal_length > 13 ? "12px" : "16px"}
            >
              $&nbsp;{Pay_bal.pat_bal ? Pay_bal.pat_bal : "00.00"}
            </PaymentBalanceData>
          </Row>
          <Div
            width="138px"
            height="138px"
            marginLeft="auto"
            marginRight="auto"
            marginTop="-12px"
          >
            <ChartBox onMouseOver={() => HideBal()}>
              <GradientCircularProgress
                color="#F00"
                size={138}
                emptyColor="#E8EDF2"
                startColor="#01C0F6"
                middleColor="#80e3ff"
                endColor="#E8EDF2"
                progress={
                  Pay_bal.pat_pay_percentage ? Pay_bal.pat_pay_percentage : "0"
                }
                strokeWidth={3}
              />
            </ChartBox>
            <ChartBox2 onMouseOver={() => HidePay()}>
              <GradientCircularProgress
                color="#F00"
                size={118}
                emptyColor="#E8EDF2"
                startColor="#F97066"
                middleColor="#fab9b4"
                endColor="#E8EDF2"
                progress={
                  Pay_bal.pat_bal_percentage ? Pay_bal.pat_bal_percentage : "0"
                }
                strokeWidth={3}
              />
            </ChartBox2>

            <ChartBoxspan
              display={PayAmt ? "block" : "none"}
              fontSize={Pay_bal.payment_length > 11 ? "12px" : "16px"}
              id="pat_pay_inside_payment_chart"
            >
              $ {Pay_bal.payment ? parseInt(Pay_bal.payment) : "00.00"}
            </ChartBoxspan>
            <ChartBoxspan
              display={BalanceAmt ? "block" : "none"}
              fontSize={Pay_bal.pat_bal_length > 11 ? "12px" : "16px"}
              id="pat_bal_inside_balance_chart"
            >
              $ {Pay_bal.pat_bal ? parseInt(Pay_bal.pat_bal) : "00.00"}
            </ChartBoxspan>
          </Div>
        </Div>
        <BorderData />
        <Button
          marginLeft="auto"
          marginRight="auto"
          background="none"
          display="flex"
        >
          {CardDetails.length > 0 ? (
            Pay_bal.pat_bal > 0 ? (
              <Text
                fontSize="16px"
                clr={globalColor}
                mt="0px"
                mb="0px"
                lineHeight="20px"
                color={globalColor}
                fontWeight="500"
                letterSpacing="0.0025em"
                onClick={() => {
                  if (stripeAllowed === false) {
                    setModalAlerShow(true);
                  } else {
                    setmakepaymentshow(true);
                  }
                }}
              >
                Make a Payment
              </Text>
            ) : (
              <Text
                fontSize="16px"
                clr={globalColor}
                mt="0px"
                mb="0px"
                lineHeight="20px"
                color={globalColor}
                fontWeight="500"
                letterSpacing="0.0025em"
                onClick={() => NoDueAlert("You Don't have any Overdue Amount")}
              >
                Make a Payment
              </Text>
            )
          ) : (
            <Text
              fontSize="16px"
              clr={globalColor}
              mt="0px"
              mb="0px"
              lineHeight="20px"
              color={globalColor}
              fontWeight="500"
              letterSpacing="0.0025em"
              onClick={() =>
                NoDueAlert("Add Your Card Details then Make a Payment")
              }
            >
              Make a Payment
            </Text>
          )}
        </Button>
      </OuterContainerbox>

      <MakePayment
        show={makepaymentshow}
        ModalHide={() => setmakepaymentshow(false)}
        makepayment="yes"
        InvoiceList={InvoiceList}
        defaultcardcheck={DefaultCardDetails[0]}
        CardDetails={CardDetails}
        patient_balance={Pay_bal.pat_bal}
        successamount={SuccessAmount}
      />
      {ModalAlerShow && (
        <StripeAlert show={ModalAlerShow} handleModalClick={handleModalClick} />
      )}
    </>
  );
};
export default BillOverview;
