import React from "react";
import moment from "moment";

import { Div, Description, CommonDiv, Text } from "../../StyledComponents";
import {
  SearchProvider,
  TnumbComponent,
  Avatar,
  AvatarImage,
} from "../StyledComponents";
import { UserContainer, UserContent } from "./Styles";
import { getUnreadCount } from "../../../StateManagement/Reducers/MessengerState";
import { useDispatch } from "react-redux";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import Tablerpin from "../../../Assets/images/Tablerpin.png";
import { Image } from "../HeadNav/Styles";
import { globalFont } from "../../utils/RepeatFunctions/Schedule";
const ProviderList = ({
  list,
  OnChangeSearch,
  sessioVal,
  handleClick,
  search,
}) => {
  const dispatch = useDispatch();
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }
  const makeItAsMinute = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    const formattedDuration = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

    return formattedDuration;
  };
  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  function compareDateTime(dateStringA, dateStringB) {
    const [dateA, timeA] = dateStringA.split(" ");
    const [dateB, timeB] = dateStringB.split(" ");

    if (dateA > dateB) {
      return 1;
    } else if (dateA < dateB) {
      return -1;
    } else {
      if (timeA > timeB) {
        return 1;
      } else if (timeA < timeB) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  const dateFormat1 = moment(
    localStorage.getItem("dateFormat") ? localStorage.getItem("dateFormat") : ""
  );
  const dateformat = dateFormat1._i;
  const dtFormatsec = `${dateformat} hh:mm a`;
  return (
    <Div
      width="37%"
      height="100%"
      padding="0px 32px 0px 40px"
      background="#ffffff"
      className="Chat_width_alt"
    >
      <Description
        fontWeight="500"
        fontSize="20px"
        lineHeight="20px"
        color="#000000"
        margin="14px 0px 35px 0px"
      >
        Chats
      </Description>

      <Div display="flex" width="100%">
        <SearchProvider searchVal={OnChangeSearch} />
      </Div>
      <UserContainer>
        {list.length > 0 ? (
          [
            ...list
              .filter((data) => data.pindate)
              .sort((a, b) => new Date(b.pindate) - new Date(a.pindate))
              .map((item, index) => (
                <UserContent
                  position="relative"
                  active={item.id === sessioVal}
                  onClick={() => {
                    let data = { Pid: Pat_ID, Provider_Id: item.id };
                    dispatch(getUnreadCount(data));
                    handleClick(item.id, item);
                  }}
                  key={item.id}
                >
                  {item.img_url === null ? (
                    <Avatar
                      width="40px"
                      height="40px"
                      border="1px solid #ffffff"
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      objectFit="unset"
                    >
                      {item.ProviderName.charAt(0).toUpperCase()}
                    </Avatar>
                  ) : (
                    <AvatarImage
                      src={File_URL + item.img_url}
                      alt="image-failed"
                      loading="lazy"
                      width="40px"
                      height="40px"
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      border="1px solid #ffffff"
                      objectFit="unset"
                    />
                  )}

                  <CommonDiv backColor="unset" position="absolute" left="50px">
                    <Text
                      className="prov_width"
                      color="#0a1629"
                      mb="4px"
                      lineHeight="22px"
                      fontSize="16px"
                      fontWeight="600"
                      textAlign="unset"
                      id={`msg_cht-p_prvd${index}`}
                    >
                      {item.ProviderName}
                    </Text>
                    <Text
                      className="chatmsg_width"
                      color="#91929e"
                      lineHeight="18px"
                      fontSize="13px"
                      fontWeight="400"
                      textAlign="unset"
                      whiteSpace="nowrap"
                      width="130px"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {item.type === "image" ? (
                        <TnumbComponent
                          message={item.message}
                          variant="image"
                        />
                      ) : item.type === "doc" ? (
                        <TnumbComponent message={item.message} variant="doc" />
                      ) : item.type === "audio" ? (
                        <TnumbComponent
                          message={makeItAsMinute(item.Voicenote_duration)}
                          variant="audio"
                        />
                      ) : (
                        item.message
                      )}
                    </Text>
                  </CommonDiv>
                  <CommonDiv
                    backColor="unset"
                    marginLeft="auto"
                    className="date_and_unreadCount"
                  >
                    <Text
                      className="date_width"
                      width="unset"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="22px"
                      color="#7d8592"
                      textAlign="unset"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {item.date &&
                        item.message !== null &&
                        moment(item.date).format(dtFormatsec)}
                      {/* {item.date} */}
                    </Text>

                    {item.unreadCount !== "0" && (
                      <Div
                        height="18px"
                        width="18px"
                        backColor="#f65160"
                        borderRadius="50%"
                        marginTop="6px"
                        marginLeft="auto"
                        padding="3px"
                        zIndex="100"
                        className="unread_alt"
                        // left="782px"
                      >
                        <Text
                          fontFamily={globalFont}
                          fontWeight="600"
                          fontSize="12px"
                          lineHeight="12px"
                          color="#ffffff"
                        >
                          {item.unreadCount}
                        </Text>

                        <Image
                          position="absolute"
                          width="20px"
                          height="20px"
                          top="27px"
                          right="40px"
                          src={Tablerpin}
                          alt="Tablerpin image"
                          className="tablerpin_right"
                        ></Image>
                      </Div>
                    )}
                    {item.unreadCount === "0" && (
                      <Image
                        position="absolute"
                        width="20px"
                        height="20px"
                        right="10px"
                        src={Tablerpin}
                        alt="Tablerpin image"
                      ></Image>
                    )}
                  </CommonDiv>
                </UserContent>
              )),
            ...list
              .filter(
                (item) =>
                  !item.pindate &&
                  item.ProviderName.toLowerCase().includes(search.toLowerCase())
              )
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((item, index) => (
                <UserContent
                  position="relative"
                  active={item.id === sessioVal}
                  onClick={() => {
                    let data = { Pid: Pat_ID, Provider_Id: item.id };
                    dispatch(getUnreadCount(data));
                    handleClick(item.id, item);
                  }}
                  key={item.id}
                >
                  {item.img_url === null ? (
                    <Avatar
                      width="40px"
                      height="40px"
                      border="1px solid #ffffff"
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      objectFit="unset"
                    >
                      {item.ProviderName.charAt(0).toUpperCase()}
                    </Avatar>
                  ) : (
                    <AvatarImage
                      src={File_URL + item.img_url}
                      alt="image-failed"
                      loading="lazy"
                      width="40px"
                      height="40px"
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      border="1px solid #ffffff"
                      objectFit="unset"
                    />
                  )}

                  <CommonDiv backColor="unset" position="absolute" left="50px">
                    <Text
                      className="prov_width"
                      color="#0a1629"
                      mb="4px"
                      lineHeight="22px"
                      fontSize="16px"
                      fontWeight="600"
                      textAlign="unset"
                      id={`msg_cht-p_prvd${index}`}
                    >
                      {item.ProviderName}
                    </Text>
                    <Text
                      className="chatmsg_width"
                      color="#91929e"
                      lineHeight="18px"
                      fontSize="13px"
                      fontWeight="400"
                      textAlign="unset"
                      whiteSpace="nowrap"
                      width="130px"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {item.type === "image" ? (
                        <TnumbComponent
                          message={item.message}
                          variant="image"
                        />
                      ) : item.type === "doc" ? (
                        <TnumbComponent message={item.message} variant="doc" />
                      ) : item.type === "audio" ? (
                        <TnumbComponent
                          message={makeItAsMinute(item.Voicenote_duration)}
                          variant="audio"
                        />
                      ) : (
                        item.message
                      )}
                    </Text>
                  </CommonDiv>
                  <CommonDiv
                    backColor="unset"
                    marginLeft="auto"
                    className="date_and_unreadCount"
                  >
                    <Text
                      className="date_width"
                      width="unset"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="22px"
                      color="#7d8592"
                      textAlign="unset"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {item.date &&
                        item.message != null &&
                        moment(item.date).format(dtFormatsec)}
                      {/* {item.date} */}
                    </Text>
                    {item.unreadCount !== "0" && (
                      <Div
                        height="18px"
                        width="18px"
                        backColor="#f65160"
                        borderRadius="50%"
                        marginTop="6px"
                        marginLeft="auto"
                        padding="3px"
                        zIndex="100"
                        className="unread_alt"
                        // left="782px"
                      >
                        <Text
                          fontFamily={globalFont}
                          fontWeight="600"
                          fontSize="12px"
                          lineHeight="12px"
                          color="#ffffff"
                        >
                          {item.unreadCount}
                        </Text>
                      </Div>
                    )}
                  </CommonDiv>
                </UserContent>
              )),
          ]
        ) : (
          <Div height="100% " display="flex" alignItems="center">
            <Text
              fontWeight="400"
              fontSize="16px"
              lineHeight="16px"
              color="#000"
            >
              No Chats to display
            </Text>
          </Div>
        )}
      </UserContainer>
    </Div>
  );
};

export default ProviderList;
